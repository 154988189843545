<template>
  <div style="max-height: 480px; overflow-y: auto">
    <a-table rowKey="uid" :columns="columns" :data-source="dataItems" size="small" :pagination="false">
      <template slot="goods" slot-scope="value, item, index">
        <GoodsSelect
          v-model="item.id"
          :defaultItem="item"
          size="small"
          style="width: 100%"
          @select="(goodsItem) => changeGoods(index, goodsItem)"
        />
      </template>
      <template slot="action" slot-scope="value, item, index">
        <TableAction :index="index" :item="item" @replace="replaceItem" @remove="removeItem" />
      </template>
      <template slot="footer">
        <a-button-group style="width: 100%">
          <a-button type="dashed" block @click="addItem">添加子产品</a-button>
        </a-button-group>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  components: {
    GoodsSelect: () => import("@/components/GoodsSelect"),
    TableAction: () => import("./TableAction"),
  },
  props: ["value"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "产品",
          dataIndex: "goods",
          width: 240,
          scopedSlots: { customRender: "goods" },
        },
        {
          title: "编号",
          dataIndex: "number",
        },
        {
          title: "条码",
          dataIndex: "barcode",
        },
        {
          title: "规格",
          dataIndex: "spec",
        },
        {
          title: "操作",
          width: 60,
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    addItem() {
      this.dataItems.push({ uid: -new Date().getTime(), id: undefined });
    },
    removeItem(index) {
      this.dataItems.splice(index, 1);
      this.handleChange();
    },
    replaceItem(index, item) {
      this.dataItems.splice(index, 1, item);
      this.handleChange();
    },
    changeGoods(index, item) {
      this.dataItems[index] = { ...this.dataItems[index], ...item };
      this.handleChange();
    },
    handleChange() {
      this.$emit("change", this.dataItems);
    },
  },
  mounted() {
    this.dataItems = this.value.map((item) => ({ uid: item.id, ...item }));
  },
};
</script>

<style scoped></style>
